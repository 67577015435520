import axios from 'axios';
import store from '../store';
import Detect from '@/lib/Detect';
import VueCookies from 'vue-cookies';
import router from '../router/index';

// accessToken 만료시 refreshToken 으로 getToken api 호출
const getAccessTokenByRefreshToken = async () => {
  const id = store.getters.userId;
  const refreshToken = VueCookies.get('refreshToken');
  try {
    const response = await axios({ method: 'post', url: '/api/auth/getToken', data: { id, refreshToken }, retry: true });
    // accessToken 재발급 됐을 때
    if (response.data.resultCode === '0') {
      const { accessToken } = response.data.result;
      store.commit('isLoggedIn', true, { root: true });
      VueCookies.set('accessToken', accessToken, '30MIN');

      // 로그인 유지 여부 쿠키
      const loginKeep = VueCookies.get('dpLoginKeep');
      // 210910 놀다가 로그아웃 되는 이슈로 인한 4h -> 8h
      VueCookies.set('refreshToken', refreshToken, loginKeep === 'Y' ? '10d' : '8h');

      if (loginKeep === 'Y') VueCookies.set('dpLoginKeep', 'Y', '10d');
      return true;
    } else {
      VueCookies.remove('refreshToken');
      VueCookies.remove('dpLoginKeep');
      VueCookies.remove('communityToken');
      store.commit('logout');
      store.commit('resetState', null);
      store.commit('isLoggedIn', false, { root: true });
      return false;
    }
  } catch (e) {
    VueCookies.remove('refreshToken');
    VueCookies.remove('dpLoginKeep');
    VueCookies.remove('communityToken');
    store.commit('logout');
    store.commit('resetState', null);
    store.commit('isLoggedIn', false, { root: true });
    throw Error(e);
  }
};

// axios 요청 interceptor add token
axios.interceptors.request.use(
  async config => {
    const accessToken = VueCookies.get('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    // 커뮤니티 인증 토큰
    const urlArray = config.url.split('/');
    if (urlArray.length > 2 && urlArray[2] === 'community') {
      const communityToken = VueCookies.get('communityToken');
      config.headers.communityToken = communityToken;
    }

    const { skipErrorHandler, ...restParams } = config.params || {};
    config.skipErrorHandler = skipErrorHandler || false;
    config.params = restParams;

    return config;
  },
  error => Promise.reject(error),
);

// axios 응답 interceptor
axios.interceptors.response.use(
  response => {
    // 동의 안된 회원일 경우
    if (response?.data?.resultCode === '10000') {
      router.push('/terms/agree');
    }
    return response;
  },
  async error => {
    if (error.config?.skipErrorHandler) {
      // 기본값 반환
      return Promise.resolve({
        status: 200,
      });
    }

    if (error.config.url.includes('ad')) return Promise.reject(error);
    // 500 서버 에러시 바로 에러페이지로 이동
    if (error?.response?.status === 500) {
      return router.replace('/error');
    }
    // 429 비정상적 많은 호출시 에러페이지. 쿠키 삭제
    if (error?.response?.status === 429) {
      return router.replace('/danger');
    }
    const errorAPI = error.config;
    // 401 인증 에러일 경우
    if (error.response?.data?.status === 401 && errorAPI.retry === undefined) {
      errorAPI.retry = true;
      // getToken
      await getAccessTokenByRefreshToken();
      return await axios(errorAPI);
    }

    // 401 에러 2번 발생시
    if (error.response?.data?.status === 401 && errorAPI.retry !== undefined) {
      await store.commit('logout');
      await store.commit('resetState', null, { root: true });
      VueCookies.remove('accessToken');
      VueCookies.remove('refreshToken');
      VueCookies.remove('communityToken');
      alert('로그인이 만료되었습니다. 다시 로그인해주세요.');
      return (location.href = `/login?redirect=${location.href}`);
    }

    if (error?.response?.status === 400 && error?.response?.data?.message) {
      // alert(error.response.data.message);
      return error.response;
    }

    router.replace('/error');
    return Promise.reject(error);
  },
);

// get api
const get = (url, params, headers) => {
  return axios.get(url, { params }, headers);
};

// post api
const post = (url, body, headers) => {
  // 공통으로 모바일 여부 추가
  const ispc = Detect.isMobile ? 'W' : 'P';
  return axios.post(`/api${url}`, { ...body, ispc }, headers);
};

/**
 * @author Ash
 * @description 기존 api와 신설된 api의 사양이 달라서 추가 생성
 */
const postV3 = (url, body, headers) => {
  // 공통으로 모바일 여부 추가
  return axios.post(`/api/v3${url}`, { ...body }, headers);
};

// put api
const put = (url, body, headers) => {
  return axios.put(`/api${url}`, { ...body }, headers);
};

// patch api
const patch = (url, body, headers) => {
  return axios.patch(`/api${url}`, { ...body }, headers);
};

export { get, post, postV3, patch, put, getAccessTokenByRefreshToken, store };
